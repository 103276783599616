import BaseHeader from '@/components/BaseHeader'
import Countdown from '../ea-vgas/components/Countdown'
import fetchGiveawaysMixin from '@/mixins/fetch-giveaways'
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin, fetchGiveawaysMixin],
  data() {
    return {
      isLoading: true,
      isEnded: false,
      isActive: false,
      isUpcoming: false,
      baseGiveawayToken: 'AhUj3VJ',
      baseGiveaway: null,
      baseGiveawayInterval: null
    }
  },
  watch: {
    isEnded() {
      if (this.isEnded) {
        clearInterval(this.baseGiveawayInterval)
      }
    }
  },
  async created() {
    const giveawayFetchList = await this.fetchGiveawaysByIdTokens([this.baseGiveawayToken], 300)
    this.baseGiveaway = giveawayFetchList[0]

    this.baseGiveawayCheck()
    this.baseGiveawayInterval = setInterval(this.baseGiveawayCheck, 1000)
    this.isLoading = false
  },
  beforeDestroy() {
    clearInterval(this.baseGiveawayInterval)
  },
  methods: {
    baseGiveawayCheck() {
      // NOTE: it is assumed that all giveaways have the same start/end times
      // so we are just checking 'baseGiveawayToken' to trigger page states.
      this.isEnded = this.baseGiveaway?.isEnded
      this.isActive = this.baseGiveaway?.isActive
      this.isUpcoming = this.baseGiveaway?.isUpcoming
    },
    jumpToMain() {
      this.trackEvent('jump_to_main')
      this.$helpers.scrollToId('main')
    }
  },
  components: {
    BaseHeader,
    Countdown
  }
}
