export const wildHeartsManifest = [
  {
    region: 'Global',
    image: require('../../assets/themes/ea-wild-hearts/images/region-global.png'),
    giveaways: [
      {
        platform: 'ea',
        id: 12926,
        idToken: 'AhUj3VJ'
      },
      {
        platform: 'steam',
        id: 12925,
        idToken: 'eqHbgKv'
      },
      {
        platform: 'epic',
        id: 12924,
        idToken: '56jrnwU'
      },
      {
        platform: 'xbox',
        id: 12920,
        idToken: 'jF5hxnN'
      }
    ]
  },
  {
    region: 'North America',
    image: require('../../assets/themes/ea-wild-hearts/images/region-north-america.png'),
    giveaways: [
      {
        platform: 'ea',
        id: 12926,
        idToken: 'AhUj3VJ'
      },
      {
        platform: 'steam',
        id: 12925,
        idToken: 'eqHbgKv'
      },
      {
        platform: 'epic',
        id: 12924,
        idToken: '56jrnwU'
      },
      {
        platform: 'playstation',
        id: 12921,
        idToken: 'kDSxntl'
      },
      {
        platform: 'xbox',
        id: 12920,
        idToken: 'jF5hxnN'
      }
    ]
  },
  {
    region: 'Latin America',
    image: require('../../assets/themes/ea-wild-hearts/images/region-latin-america.png'),
    giveaways: [
      {
        platform: 'ea',
        id: 12926,
        idToken: 'AhUj3VJ'
      },
      {
        platform: 'steam',
        id: 12925,
        idToken: 'eqHbgKv'
      },
      {
        platform: 'epic',
        id: 12924,
        idToken: '56jrnwU'
      },
      {
        platform: 'playstation',
        id: 12922,
        idToken: 'BbJ2C7W'
      },
      {
        platform: 'xbox',
        id: 12920,
        idToken: 'jF5hxnN'
      }
    ]
  },
  {
    region: 'Europe',
    image: require('../../assets/themes/ea-wild-hearts/images/region-europe.png'),
    giveaways: [
      {
        platform: 'ea',
        id: 12926,
        idToken: 'AhUj3VJ'
      },
      {
        platform: 'steam',
        id: 12925,
        idToken: 'eqHbgKv'
      },
      {
        platform: 'epic',
        id: 12924,
        idToken: '56jrnwU'
      },
      {
        platform: 'playstation',
        id: 12923,
        idToken: 'NVXMDnt'
      },
      {
        platform: 'xbox',
        id: 12920,
        idToken: 'jF5hxnN'
      }
    ]
  },
  {
    region: 'Middle East & Asia',
    image: require('../../assets/themes/ea-wild-hearts/images/region-middle-east-asia.png'),
    giveaways: [
      {
        platform: 'ea',
        id: 12926,
        idToken: 'AhUj3VJ'
      },
      {
        platform: 'steam',
        id: 12925,
        idToken: 'eqHbgKv'
      },
      {
        platform: 'epic',
        id: 12924,
        idToken: '56jrnwU'
      },
      {
        platform: 'playstation',
        id: 12927,
        idToken: 'bIuCgEi'
      },
      {
        platform: 'xbox',
        id: 12920,
        idToken: 'jF5hxnN'
      }
    ]
  }
]
