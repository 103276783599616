<template lang="pug">
page.ea-wildhearts(
  page-meta-title='WILD HEARTS™',
  :show-header='false',
  :ga-event-category='gaEventCategory',
  :ga-event-label='gaEventLabel'
)
  //- Content
  template(slot='content')
    //- Banner - when needed
    page-section.ea-wildhearts-section.ea-wildhearts-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img

    //- Main
    #main
      page-section.ea-wildhearts-section.ea-wild-hearts-section--enter.page-pt-md-alt(align='center')
        template(slot='content')
          span.page-mb-md
            template(v-if='isEnded') <span class='ff-marcellus fs-mega xl:fs-huge'>Thank you for participating</span><br /><span class='fs-sm xl:fs-md'>Select your region and platform to continue and view winners</span>
            template(v-if='isUpcoming') <span class='ff-marcellus fs-mega xl:fs-huge'>Check Back Soon</span>
            template(v-if='isActive') <span class='ff-marcellus fs-mega xl:fs-huge'>Enter To Win</span><br /><span class='fs-sm xl:fs-md'>Select your region and platform of choice below to enter</span>

          //- Countdown
          .page-mt-md-alt.fs-sm-alt(v-if='isUpcoming && !!baseGiveaway')
            stacked-countdown(
              :giveaway='baseGiveaway',
              label-classes='fs-lg ff-marcellus md:fs-mega page-mb-sm page-pl-xs md:page-pl-sm',
              count-wrap-classes='uppercase text-center',
              timeslot-classes='w-80p--until-md w-100p--after-md',
              time-count-classes='fs-lg md:fs-mega',
              time-label-classes='fs-xs md:fs-md'
            )

          //- Game Giveaway List
          .ea-wildhearts-game__list.d-flex.flex-wrap.align-items-start(v-if='!isUpcoming')
            wild-hearts-list.page-mt-lg(
              v-for='game in gameList',
              :game='game',
              :key='game.region',
              :ended='isEnded',
              :region-id='game.region',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )

          template(v-if='!isUpcoming')
            .eligible-rules.page-mt-md.text-left
              | *Global includes countries eligible for this sweepstakes and excludes some regions. For a full list of eligible regions, see Official Sweepstakes Rules.
  //- Footer
  template(slot='footer')
    base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
      template(slot='custom-image')
        .mt-3.mt-sm-0
          img.mr-2(:src='esrbRatingSrc', height='60', alt='ESRB Rating')
          img(:src='pegiRatingSrc', height='60', alt='PEGI Rating')
</template>
<script>
import wildHeartsPageMixin from './ea-wild-hearts-mixin'
import wildHeartsList from './WildHeartsGameTile.vue'
import { wildHeartsManifest } from './ea-wild-hearts-manifest'
import BaseFooter from '../../components/BaseFooter.vue'
import StackedCountdown from '../../components/StackedGiveawayStartsIn.vue'

export default {
  name: 'WildHearts',
  mixins: [wildHeartsPageMixin],
  data() {
    return {
      regionId: '',
      esrbRatingSrc: require('../../assets/themes/ea-wild-hearts/images/esrb-rating.svg'),
      pegiRatingSrc: require('../../assets/themes/ea-wild-hearts/images/pegi.png'),
      activeTitle: 'Enter to Win!',
      activeIntro: '',
      endedTitle: 'See the Winners',
      endedIntro: '',
      gameList: wildHeartsManifest
    }
  },
  components: {
    wildHeartsList,
    BaseFooter,
    StackedCountdown
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-wild-hearts/scss/main.scss" />
