<template lang="pug">
component.bg-color-ea-vgas-countdown-bg.px-2.py-2.lh-1.rounded-sm(
  :is='countdownTemplate',
  :giveaway='giveaway',
  :label='label',
  label-color='ea-vgas-countdown-label',
  countdown-color='ea-vgas-countdown'
)
</template>
<script>
import GiveawayStartsIn from '@/components/GiveawayStartsIn'
import GiveawayEndsIn from '@/components/GiveawayEndsIn'

export default {
  props: {
    giveaway: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    countdownTemplate() {
      return this.isActive ? GiveawayEndsIn : GiveawayStartsIn
    },
    label() {
      return this.isActive ? 'Ending In' : 'Starting In'
    }
  },
  components: {
    GiveawayStartsIn,
    GiveawayEndsIn
  }
}
</script>
