<template lang="pug">
.ea-wildhearts-game-tile__list.w-100
  .ea-wildhearts-game-tile__enter-list.d-flex.flex-column.mt-2.pl-1.w-100
    a.ea-wildhearts-game-tile__enter-list-item.text-left.d-block(
      v-for='g in giveawayList',
      :key='g.idToken',
      :href='giveawayLink(g.idToken)',
      :aria-label='giveawayAriaLabel(g)',
      target='_blank',
      @mousedown='trackEvent(gaEventAction(g))'
    )
      img(:src='logos[g.platform]', :alt='g.platform', :class='[g.platform]')
</template>
<script>
import startCase from 'lodash/startCase'
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  data() {
    return {
      logos: {
        ea: require('../../assets/themes/ea-wild-hearts/images/logo-ea.svg'),
        playstation: require('../../assets/themes/ea-wild-hearts/images/logo-playstation.svg'),
        steam: require('../../assets/themes/ea-wild-hearts/images/logo-steam.svg'),
        xbox: require('../../assets/themes/ea-wild-hearts/images/logo-xbox.svg'),
        epic: require('../../assets/themes/ea-wild-hearts/images/logo-epic.svg')
      }
    }
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    giveawayList: {
      type: Array,
      required: true
    },
    ended: {
      type: Boolean,
      required: false,
      default: false
    },
    regionId: {
      type: String,
      required: true
    }
  },
  methods: {
    giveawayLink(idToken) {
      return `${this.$helpers.giveawayUrl(idToken)}${this.ended ? '/ended' : ''}`
    },
    gaEventAction(giveaway) {
      let action = `to_giveaway__${this.game.id}__${giveaway.platform}`

      if (this.regionId) {
        action = `${action}__${this.regionId}`
      }

      return `${action}__${giveaway.idToken}`
    },
    giveawayAriaLabel(giveaway) {
      return `${startCase(giveaway.platform)} Giveaway`
    }
  }
}
</script>
